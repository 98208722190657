table.table-invoice {
    border-collapse: collapse;
    border: 1px solid rgb(140 140 140);
    /*font-family: sans-serif;*/
    font-size: 0.8rem;
    letter-spacing: 1px;
    width: 80%;
}

caption.table-invoice {
    caption-side: bottom;
    padding: 10px;
    font-weight: bold;
}

thead.i-thead, tfoot.i-tfoot {
    background-color: rgb(228 240 245);
}

.table-invoice  th td{
    border: 1px solid rgb(160 160 160);
    padding: 8px 10px;
    text-align: center;
}

.table-invoice td:last-of-type {
    text-align: center;
}
tbody.i-tbody > tr:nth-of-type(even) {
    background-color: rgb(237 238 242);
}

tfoot.i-tfoot th {
    text-align: right;
}

tfoot.i-tfoot td {
    font-weight: bold;
}