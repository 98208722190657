
.tabler tr:nth-child(even){
    background: #f3f3f3;
}
.tabler td:first-child {
    /*border: none;*/
    border-radius: 8px 0 0 8px;
}
.tabler td:last-child {
    /*border: none;*/
    border-radius: 0 8px 8px 0;
}

.tabler td {
    text-align: center;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    vertical-align: top;
}
.tabler th{
    padding: 10px 15px;
    background: #1676FD;
    border-radius: 8px;
}

.cash-quantity:hover{
    background: rgba(0, 0, 0, 0.31);
}

.tabler tbody{
    border: 1px black solid;
    background-color: rgba(114, 163, 255, 0.36);
}