.fancy {
    --b: 4px;   /* control the border thickness */
    --w: 50vh;  /* control the width of the line*/
    --g: 40px;  /* control the gap */
    --c: #2f8ade;

    width: fit-content;
    padding: 0 1em;
    line-height: 1.6em;
    border: 1px solid;
    color: #fff;
    background:
            conic-gradient(from   45deg at left ,var(--c) 25%,#0000 0) 0,
            conic-gradient(from -135deg at right,var(--c) 25%,#0000 0) 100%;
    background-size: 51% 100%;
    background-origin: border-box;
    background-repeat: no-repeat;
    border-image:
            linear-gradient(
                    #0000      calc(50% - var(--b)/2),
                    var(--c) 0 calc(50% + var(--b)/2),
                    #0000    0)
            1/0 var(--w)/calc(var(--w) + var(--g));
    margin-inline: auto;
}

.heading-1 {
    font-family: 'Roboto Slab', serif;
    font-size: 1.5em;
    letter-spacing: 0.08em;
    font-weight: 300;
    color: #F60;
    text-shadow: 0 1px 1px #FFFFFF;
    text-transform: uppercase;
}

.heading-2 {
    font-family: 'Roboto Slab', serif;
    font-size: 1.5em;
    letter-spacing: 0.08em;
    font-weight: 300;
    color: #1589FF;
    text-shadow: 0 1px 1px #FFFFFF;
    text-transform: uppercase;
}

.heading-3 {
    font-family: 'Roboto Slab', serif;
    font-size: 1.5em;
    letter-spacing: 0.08em;
    font-weight: 300;
    color: #FF00FF;
    text-shadow: 0 1px 1px #FFFFFF;
    text-transform: uppercase;
}

.divider-1 {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 3px;
    margin: 0.5em 0px 1.5em;
}

.divider-1 span {
    display: block;
    width: 50px;
    height: 3px;
    background-color: #F60;
}

.divider-2 {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0px 1.5em;
}

.divider-2 span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: #1589FF;
}

.divider-3 {
    border-bottom: 1px solid #FFF;
    background-color: #DADADA;
    height: 1px;
    margin: 0.5em 0px 1.5em;
}

.divider-3 span {
    display: block;
    width: 50px;
    height: 1px;
    background-color: #FF00FF;
}

.curved {
    position: relative;
    background: rgba(35, 35, 35, 0.28);
    height: 3vh;
    border-bottom-left-radius: 50% 31%;
    border-bottom-right-radius: 50% 31%;
    color: #FFFFFF;
    text-align: center;
    font-size: 20px;
}

.item-card:hover{
    transform: scale(1.01);
    transition-duration: 0.2s;
}