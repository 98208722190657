
.pattern-container {
    position: relative;
    /*overflow: hidden;*/
}

.pattern {
    position: absolute;
    pointer-events: none;
    width: 100%; /* Adjust the size of the pattern */
    height: 100%; /* Adjust the size of the pattern */
    /*background: url('3.jpg') repeat;*/
    background: url('11.png') repeat;
    background-size: 270px;
    transition: ease-out 0.5s;
    z-index: -1;
    opacity: 1;

    /* Add animation properties */
    animation: fadeIn 0.5s ease-in;
}
.pattern-upper {
    position: absolute;
    pointer-events: none;
    width: 100%; /* Adjust the size of the pattern */
    height: 100%; /* Adjust the size of the pattern */
    /*background: url('3.jpg') repeat;*/
    background: rgba(0, 0, 0, 0.09);
    background-size: 270px;
    transition: ease-out 0.5s;
    z-index: -1;
    opacity: 1;

    /* Add animation properties */
    animation: fadeIn 0.5s ease-in;
}


@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}